/* @tailwind base;
@tailwind components;
import utilities; */

/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  box-sizing: border-box;
  background-color:rgb(215, 215, 215);
  @media screen (max-width: 440px) {
    font-size: 0.5em;
  }
}

iframe {
  pointer-events: none;
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button:disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

/* Greens */
/* export const PRIMARY = '#003300'
export const SECONDARY = '#1b5e20' */
:root {
  /* $green-primary: #019444;
$green-dark: #006519;
$green-extra-dark: #003900;
$green-light: #4fc671; */
  --BLUE: rgb(60, 128, 250);
  --LIGHT_BLUE: #3296ff;
  --LIGHT_GREY: rgb(240, 240, 240);
  /* --PRIMARY: rgb(60, 128, 250); */
  /* --PRIMARY: #003900; */
  --PRIMARY: #003900;
  /* #019444; */
  --SECONDARY: #8bc34a;
  --PRIMARY_DARK: #006519;
  /* --SECONDARY: #3296ff; */
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgb(230, 230, 230);
  border-radius: 4px;
  box-shadow: 4px 4px 12px rgb(180, 180, 180);
  /* max-width: 75vw; */
  max-width: 980px;
  /* margin-left: auto;
  margin-right: auto; */
}

input {
  box-shadow: 4px 4px 4px black;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.dashboard-name {
  min-width: 50vw;
  max-height: 100px;
  padding: 2rem;
}

.dashboard-stat {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.faded {
  color: rgb(150, 150, 150);
  font-weight: bold;
}

.label {
  color: rgb(180, 180, 180);
  font-weight: bold;
}

.value {
  color: black;
}

.center-vertical {
  margin-top: auto;
  margin-bottom: auto;

}

.submit {
  background-color: var(--PRIMARY_DARK);
  color: white;
  min-height: 48px;
  border-radius: 8px;
}


#subnav {
  position: absolute;
  /* position: fixed; */
  /* float: right; */
  /* height: 100%; */
  right: 6rem;
  top: 1rem;
}

#avatar {
  position: absolute;
  float: right;
  right: 1rem;
  top: 1rem;
}

#avatar>.dropdown {
  position: relative;
  top: 16px;
  width: 400px;
  height: 80px;
  background-color: blue;
}

#circle-button {
  height: 64px;
  width: 64px;
  border-radius: 32px;
  /* background-color: var(--SECONDARY); */
  text-align: center;
  margin: 0.5rem;
  /* box-shadow: 2px 2px 12px grey; */
  color: white;

}

#progress-bar {
  position: absolute;
  top: 0;
  left: 256px;
  right: 0;
  /* padding: 1rem; */
  min-height: 80px;
  /* width: 100%; */
  background-color: rgb(240, 240, 240);
  box-shadow: 4px 4px 12px rgb(180, 180, 180);
}

#progress-bar:first-child>div>button {
  border-right: 2px solid rgb(200, 200, 200);
}

#progress-bar:last-child>div>button {
  border-right-width: 0px;
}

.unavailable {
  background-color: rgb(210, 210, 210);
  color: rgb(120, 120, 120);
}

.complete {
  background-color: var(--PRIMARY);
  color: white;
  border-right-color: rgba(60, 128, 250, 0.5);
}

#onboard-progress-bar {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: var(--LIGHT_GREY);
  /* box-shadow: 4px 4px 12px rgba(180,180,180, 0.5); */
}

#onboard-progress-bar>.item {
  flex: 1;
  padding: 16px;
  text-align: center;
  border-right-color: rgb(200, 200, 200);
  border-right-width: 2px;
}

#onboard-progress-bar>.complete {
  background-color: var(--SECONDARY);
  color: white;
}


#agreement {
  max-height: 55vh;
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
}

#onboard-header {
  position: relative;
  /* top: 0px;
  left: 10%; */
  /* margin-left: auto; */
  display: flex;
  justify-content: space-between;
  /* margin-right: auto; */
  /* max-width: 80vw; */
  padding-bottom: 1rem;
}

#onboard-header>div:first-child {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

#onboard-header>p:last-child {
  color: rgba(255, 255, 255, 0.7);
  font-size: 2rem;
  font-weight: bold;
}

.disabled {
  opacity: 0.5;
}

.subscription-button {
  /* position: absolute; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* right: 32px; */
  /* bottom: 32px; */
  height: 48px;
  width: 280px;
  /* padding: 16px; */
  max-width: 100%;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 32px;
  background-color: var(--PRIMARY_DARK);
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background-color: #8bc34a;
  color: white;
  padding: 16px;
  margin: 4px;
  border-radius: 16px;
  /* background-color: #8bc34a; */
}

.tag:first-child {
  margin-left: 0px;
}

#header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: var(--PRIMARY);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 300px;
}

.shimmer {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #f4f4f4 8%, #e8e8e8 38%, #f4f4f4 54%);
  background-size: 1000px 640px;
  border-radius: 4px;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}